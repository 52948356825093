import { ApiProperty } from '@nestjs/swagger';
import { IsEnum, IsString } from 'class-validator';
import { PermissionLevel, PermissionType } from '../../permission/permission.entity';

export const basePath: string = 'user';

export enum ActivationStatus {
	Pending = 'pending',
	Activated = 'activated'
}

export enum UserRole {
	SuperAdmin = 'super-admin',
	Admin = 'admin',
	User = 'user',
	Analyst = 'analyst',
	Guest = 'guest',
	Sales = 'sales',
	OutsideAgency = 'outside-agency'
}

export const AgencyRoles = [UserRole.OutsideAgency];

export const UserRoleMap = Object.entries(UserRole).reduce((acc, cur, idx) => {
	acc[cur[1]] = idx;
	return acc;
}, {});

export interface Profile {
	nameFirst: string;
	nameLast: string;
	phoneNumber?: string;
	birthDate?: string;
	referrer?: string;
	defaultQuickViewId?: string;
	defaultPlanningQuickViewId?: string;
}

export interface PublicProfile {
	nameFirst: string;
	nameLast: string;
}

export class PermissionRequest {
	@IsEnum(PermissionType)
	@ApiProperty()
	type: PermissionType;

	@IsEnum(PermissionLevel)
	@ApiProperty()
	level: PermissionLevel;

	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true })
	ids: string[];
}
