export default abstract class QueryFragmentGenerator {
	public abstract getFrom(whereOptions?: any, alias?: string);
	public abstract getGroupBy(orderKey: any, alias?: string);

	/**
	 *
	 * @param orderKey which entity property to order by
	 * @param strategy ASC OR DESC
	 * @param alias entity alias
	 * @param skipStrategy if true, the strategy will be skipped in the order by clause string
	 * @param isOuterOrder if true, the order by clause string will be generated for based on alias and orderKey
	 */
	public abstract getOrderBy(orderKey: any, strategy: 'ASC' | 'DESC', alias?: string, skipStrategy?: boolean, isOuterOrder?: boolean);
}
